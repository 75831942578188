import { Grid, Tooltip } from "@mui/material";
import TurnoInfo from "../turnoInfo";
import { useWidth } from "../../../../hooks";
import { UseLiquidacionContext } from "../../../../contexts";
import { ColumnAlign, ColumnPriority, ColumnType, DataTable, ExportDialog } from "@paul-igas/igas-react-components";
import OptionTableRow from "../optionTableRow";
import { VpnLockOutlined } from "@mui/icons-material";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { exportToPDF, setIdLiquidacion } from "../../../../store/operaciones/liquidaciones";
import { hasDispatchError } from "../../../../api/services/service";
import { showInWindow } from "../../../helpers/documents";
import DeleteDialog from "../dialogs/deleteDialog";

const esLiqGlobal = (value) =>
  value.esGlobal ? (
    <Tooltip title='Liquidación Global'>
      <VpnLockOutlined fontSize='small' />
    </Tooltip>
  ) : (
    zeroPad(value.noIsla, 3)
  );

const TABLE_DEFAULT = {
  columns: [
    { text: "Isla", name: "_", icon: true, colAlign: ColumnAlign.Center, renderCell: esLiqGlobal },
    { text: "Folio", name: "folio", colType: ColumnType.DataKey, colAlign: ColumnAlign.Center, digitos: 6 },
    { text: "Despachador", name: "nombreDespachador", colPriority: ColumnPriority.Normal },
    {
      text: "Combustible",
      name: "totalCombustible",
      colPriority: ColumnPriority.Low,
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Prod. Diversos",
      name: "totalDiversos",
      colPriority: ColumnPriority.Low,
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    {
      text: "Total",
      name: "totalGeneral",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export const ListadoLiqSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screen = useWidth();
  const { turnoLoading, turnoActual, loading, liquidaciones, handleRefresh } = UseLiquidacionContext();

  const modalDelete = useModal(false);
  const modalExport = useModal(false);

  const handleSelect = (liq) => {
    dispatch(setIdLiquidacion(liq));
  };

  const handleEdit = () => {
    navigate("/operaciones/liquidaciones/editar");
  };

  const handleExport = (id) => {
    modalExport.open();
    let params = { id: id };
    dispatch(exportToPDF(params))
      .then(hasDispatchError)
      .then(showInWindow)
      .then(modalExport.close)
      .catch(modalExport.close);
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* turno */}
        <Grid item xs={12}>
          <TurnoInfo loading={turnoLoading} turno={turnoActual} liquidaciones={liquidaciones} screen={screen} />
        </Grid>

        <Grid item xs={12}>
          <DataTable
            table={TABLE_DEFAULT}
            data={liquidaciones}
            loading={loading}
            showEmptyRows={false}
            rowOptions={
              <OptionTableRow
                onEdit={handleEdit}
                onDelete={modalDelete.open}
                onSelect={handleSelect}
                onExport={handleExport}
              />
            }
          />
        </Grid>
      </Grid>

      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <ExportDialog open={modalExport.isShowing} onClose={modalExport.close} />
    </>
  );
};

export default ListadoLiqSection;
