import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Delete,
  ExportarValesPendientes,
  GetById,
  GetByIdTurno,
  GetDiferenciaValesTurno,
  GetImporteValesIslaTurno,
  GetNoExportadosByIdTurno,
  GetValesSinLiquidarTurno,
  GetResumenValesCombustible,
  GetResumenValesIsla,
  Insert,
  Update,
  GetValesFacturados,
} from "../../api/services/operaciones/valesCredito";
import { hasErrorRedux } from "../../api/services/service";

export const selectByIdTurno = createAsyncThunk("valesCredito/selectByIdTurno", async (params, { rejectWithValue }) => {
  try {
    return await GetByIdTurno(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getById = createAsyncThunk("valesCredito/getById", async (params, { rejectWithValue }) => {
  try {
    return await GetById(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("valesCredito/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("valesCredito/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("valesCredito/delete", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const importeValesLiq = createAsyncThunk("valesCredito/importeValesLiq", async (params, { rejectWithValue }) => {
  try {
    return await GetImporteValesIslaTurno(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectNoExportadosByIdTurno = createAsyncThunk(
  "valesCredito/getNoExportadosByIdTurno",
  async (params, { rejectWithValue }) => {
    try {
      return await GetNoExportadosByIdTurno(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const exportarValesPendientes = createAsyncThunk(
  "valesCredito/exportarValesPendientes",
  async (params, { rejectWithValue }) => {
    try {
      return await ExportarValesPendientes(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getDiferenciaValesTurno = createAsyncThunk(
  "valesCredito/diferencia",
  async (params, { rejectWithValue }) => {
    try {
      return await GetDiferenciaValesTurno(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getValesSinLiquidarTurno = createAsyncThunk(
  "valesCredito/sinLiquidar",
  async (params, { rejectWithValue }) => {
    try {
      return await GetValesSinLiquidarTurno(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const resumenValesIsla = createAsyncThunk(
  "valesCredito/resumenValesIsla",
  async (params, { rejectWithValue }) => {
    try {
      return await GetResumenValesIsla(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const resumenValesCombustibles = createAsyncThunk(
  "valesCredito/resumenValesCombustibles",
  async (params, { rejectWithValue }) => {
    try {
      return await GetResumenValesCombustible(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const getValesFacturados = createAsyncThunk("valesCredito/valesFacturados", async (params, { rejectWithValue }) => {
  try {
    return await GetValesFacturados(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  valesCredito: [],
  valesCreditoNoExportados: [],
  valeCredito: {},
  valesCreditoSinLiquidar: [],
  valesFacturados: [],
  error: {},
  valeCreditoIdSelected: "",
  loading: {
    getByIdTurno: false,
    getById: false,
    insert: false,
    delete: false,
    update: false,
    importeVales: false,
    getNoExportadosByIdTurno: false,
    exportarValesPendientes: false,
    getValesSinLiquidarTurno: false,
    getResumenValesIsla: false,
    getResumenValesCombustibles: false,
    getValesFacturados: false,
  },
};

const slice = createSlice({
  name: "valesCredito",
  initialState,
  reducers: {
    setValeCreditoIdSelected: (state, action) => {
      state.valeCreditoIdSelected = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    //selectByIdTurno
    builder.addCase(selectByIdTurno.pending, (state) => {
      state.loading.getByIdTurno = true;
    });
    builder.addCase(selectByIdTurno.fulfilled, (state, action) => {
      state.error = {};
      state.valesCredito = action.payload;
      state.loading.getByIdTurno = false;
    });
    builder.addCase(selectByIdTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.valesCredito = [];
      state.loading.getByIdTurno = false;
    });
    builder.addCase(getById.pending, (state) => {
      state.loading.getById = true;
    });
    builder.addCase(getById.fulfilled, (state, action) => {
      state.error = {};
      state.valeCredito = action.payload;
      state.loading.getById = false;
    });
    builder.addCase(getById.rejected, (state, action) => {
      state.error = action.payload;
      state.valeCredito = {};
      state.loading.getById = false;
    });
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    builder.addCase(importeValesLiq.pending, (state) => {
      state.loading.importeVales = true;
    });
    builder.addCase(importeValesLiq.fulfilled, (state) => {
      state.error = {};
      state.loading.importeVales = false;
    });
    builder.addCase(importeValesLiq.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.importeVales = false;
    });
    builder.addCase(selectNoExportadosByIdTurno.pending, (state) => {
      state.loading.getNoExportadosByIdTurno = true;
    });
    builder.addCase(selectNoExportadosByIdTurno.fulfilled, (state, action) => {
      state.error = {};
      state.valesCreditoNoExportados = action.payload;
      state.loading.getNoExportadosByIdTurno = false;
    });
    builder.addCase(selectNoExportadosByIdTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.valesCreditoNoExportados = [];
      state.loading.getNoExportadosByIdTurno = false;
    });
    builder.addCase(exportarValesPendientes.pending, (state) => {
      state.loading.exportarValesPendientes = true;
    });
    builder.addCase(exportarValesPendientes.fulfilled, (state) => {
      state.error = {};
      state.loading.exportarValesPendientes = false;
    });
    builder.addCase(exportarValesPendientes.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.exportarValesPendientes = false;
    });
    builder.addCase(getValesSinLiquidarTurno.pending, (state) => {
      state.loading.getValesSinLiquidarTurno = true;
    });
    builder.addCase(getValesSinLiquidarTurno.fulfilled, (state, action) => {
      state.error = {};
      state.valesCreditoSinLiquidar = action.payload;
      state.loading.getValesSinLiquidarTurno = false;
    });
    builder.addCase(getValesSinLiquidarTurno.rejected, (state, action) => {
      state.error = action.payload;
      state.valesCreditoSinLiquidar = [];
      state.loading.getValesSinLiquidarTurno = false;
    });
    builder.addCase(resumenValesIsla.pending, (state) => {
      state.loading.getResumenValesIsla = true;
    });
    builder.addCase(resumenValesIsla.fulfilled, (state) => {
      state.error = {};
      state.loading.getResumenValesIsla = false;
    });
    builder.addCase(resumenValesIsla.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getResumenValesIsla = false;
    });
    builder.addCase(resumenValesCombustibles.pending, (state) => {
      state.loading.getResumenValesCombustibles = true;
    });
    builder.addCase(resumenValesCombustibles.fulfilled, (state) => {
      state.error = {};
      state.loading.getResumenValesCombustibles = false;
    });
    builder.addCase(resumenValesCombustibles.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getResumenValesCombustibles = false;
    });
    builder.addCase(getValesFacturados.pending, (state) => {
      state.loading.getValesFacturados = true;
    });
    builder.addCase(getValesFacturados.fulfilled, (state, action) => {
      state.error = {};
      state.valesFacturados = action.payload;
      state.loading.getValesFacturados = false;
    });
    builder.addCase(getValesFacturados.rejected, (state, action) => {
      state.error = action.payload;
      state.valesFacturados = [];
      state.loading.getValesFacturados = false;
    });
  },
});

export default slice.reducer;
export const { setFilter, clearErrors, setValeCreditoIdSelected } = slice.actions;
