import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { hasDispatchError } from "../../../../api/services/service";
import { remove } from "../../../../store/operaciones/valescredito";
import { useDispatch, useSelector } from "react-redux";
import { useModal } from "@paul-igas/igas-react-hooks";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, valeCreditoIdSelected } = useSelector((state) => state.valesCredito);

  const successMsg = useModal(false);
  const onSubmit = () => {
    dispatch(remove(valeCreditoIdSelected.id))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Eliminar paquete de vales de crédito</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea eliminar el paquete de vales del cliente {boldText(valeCreditoIdSelected.nombre)}?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' disabled={loading.delete} onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
    </>
  );
}

export default DeleteDialog;
