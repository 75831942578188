import api from "../service";

const _controller = "/valeCredito/ClientesEstacion";

export async function GetClientesEstacion(params) {
  const clie = params.filtro.value.toString();
  const res = await api.get(`${_controller}/${clie}`);
  return res.data;
}

export default {
  GetClientesEstacion
};
