import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress } from "@mui/material";
import { Busqueda, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { selectClientes, setClientesList } from "../../../../store/consultas/clientes";
import { hasDispatchError } from "../../../../api/services/service";

const keyFormat = (value) => zeroPad(value.noClie, 4);

const TABLE_DEFAULT = {
  columns: [
    { text: "Cliente", name: "noClie", renderCell: keyFormat },
    { text: "Nombre", name: "nombre" },
    { text: "RFC", name: "rfc" },
  ],
};

const _filter = {
  filtro: {
    value: "",
  },
};

export const ConsultarClientes = ({ open, onClose, postSubmit, error }) => {
  const dispatch = useDispatch();
  const { loading, clientes } = useSelector((state) => state.clientes);
  const [errorMessage, setErrorMessage] = useState("");
  const [clienteSelected, setClienteSelected] = useState(null);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const initFilter = () => {
    _filter.filtro.value = "";
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    initFilter();
    dispatch(setClientesList([]));
    onClose();
  };

  const handleRefresh = (_filter) => {
    dispatch(selectClientes(_filter));
  };

  function initDialog() {
    if (open) {
      initFilter();
      dispatch(setClientesList([]));
      dispatch(selectClientes(_filter))
        .then(hasDispatchError)
        .catch(() => {});
    }
  }

  const handleChangerFilter = (_filter) => {
    handleRefresh(_filter);
  };

  const handleSelectitem = (id) => {
    setClienteSelected(id);
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status <= 409 && warningMsg.open();
      error.status >= 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dangerMsg.close();
    warningMsg.close();
  };

  const onSubmit = () => {
    if (clienteSelected.nombre.length <= 1) return;
    postSubmit(clienteSelected);
    onClose();
  };
  useEffect(handleError, [error]);
  useEffect(initDialog, [open]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='md' scroll='paper'>
        {loading.getClientes && <LinearProgress />}
        <DialogTitle>Seleccionar cliente</DialogTitle>
        <DialogContent dividers>
          <Busqueda
            table={TABLE_DEFAULT}
            filter={_filter}
            onSetFilter={handleChangerFilter}
            data={clientes}
            onSelect={handleSelectitem}
            loading={loading.getClientes}
          />
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.getClientes} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={JSON.stringify(clienteSelected) === "{}"} onClick={onSubmit}>
            Seleccionar
          </Button>
        </DialogActions>
      </Dialog>
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={errorMessage} />
    </>
  );
};

export default ConsultarClientes;
