import { Button, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { UseTurnoContext } from "../../../../contexts";

const ValesExportados = ({ turno }) => {
  const { modalExportarVales, setTurnoExportarVales } = UseTurnoContext();

  function handleConsultarValesNoExportados() {
    setTurnoExportarVales(turno);
    modalExportarVales.open();
  }
  return (
    <>
      {turno.estadoValesExportados === 0 ? (
        <Tooltip title='Todos los vales exportados' arrow>
          <Button onClick={() => {}}>
            <FiberManualRecordIcon fontSize='small' style={{ color: "#16FB03" }} />
          </Button>
        </Tooltip>
      ) : turno.estadoValesExportados === 1 ? (
        <Tooltip title='Faltan vales de exportar' arrow>
          <Button onClick={() => handleConsultarValesNoExportados()}>
            <FiberManualRecordIcon fontSize='small' style={{ color: "#FFA500" }} />
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title='Ningún vale exportado' arrow>
          <Button onClick={() => handleConsultarValesNoExportados()}>
            <FiberManualRecordIcon fontSize='small' style={{ color: "#FF0000" }} />
          </Button>
        </Tooltip>
      )}
    </>
  );
};

export default ValesExportados;
