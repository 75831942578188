import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetConfiguration, variables } from "../../../helpers/variablesEstacion";
import { monedaMX } from "@paul-igas/igas-react-hooks";
import { Alert, AlertTitle, Button, Grid } from "@mui/material";
import { DenominacionesPagos, TiposPagoPagos, TotalesPagos } from "../pagosStep";
import { importeValesLiq } from "../../../../store/operaciones/valescredito";
import { hasDispatchError } from "../../../../api/services/service";

export const PagosStep = ({ data }) => {
  const {
    denominaciones,
    pagos,
    mostrarDenominaciones,
    isLoading,
    totales,
    diferencia,
    diferenciaAceites,
    faltanteSobrante,
    screen,
    idIsla,
    idTurno,
    actualizaDenomsIsla,
    actualizaPagosIsla,
    actualizaPagoVales,
    cuadrarLiquidacion,
  } = usePagosStep(data);

  const dispatch = useDispatch();

  const inicializaVale = () => {
    if ((pagos?.length ?? 0) > 0) {
      if (pagos?.findIndex((p) => p?.clase === 6) !== -1)
      {
        dispatch(importeValesLiq({ idIsla: idIsla, idTurno: idTurno }))
        .then(hasDispatchError)
        .then(({ payload }) => {
          actualizaPagoVales(payload);
        })
        .catch(() => {});
      }
    }
  };

  useEffect(inicializaVale, [pagos]);

  return (
    <div style={{ paddingTop: "8px", marginBottom: "8px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5} lg={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item xs={12}>
              <TotalesPagos totales={totales} diferencia={diferencia} />
            </Grid>

            {!isLoading && Math.abs(diferenciaAceites) > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <AlertTitle>
                        Diferencia de {monedaMX.format(diferenciaAceites)} en pagos de productos diversos
                      </AlertTitle>
                      Los pagos de <b>Productos Diversos </b> se deben aplicar con tipos de pago de clase "Aceites".
                    </Alert>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {!isLoading && Math.abs(diferencia) > 0 && (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <AlertTitle>Diferencia de {monedaMX.format(diferencia)} en pagos totales</AlertTitle>
                      Para continuar debe igualar el total de pagos con el total de liquidación.
                    </Alert>
                  </Grid>

                  {Math.abs(diferencia) > 0 && faltanteSobrante !== 0 && (
                    <Grid item xs={12} textAlign='end'>
                      <Button
                        variant='contained'
                        style={{ marginRight: 16 }}
                        size='small'
                        disableElevation
                        onClick={cuadrarLiquidacion}
                      >
                        Cuadrar liquidación
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={7} lg={mostrarDenominaciones ? 8 : 6}>
          <Grid container direction={mostrarDenominaciones ? "row" : "column"} spacing={2}>
            <Grid item xs={12} lg={6}>
              <TiposPagoPagos
                pagos={pagos}
                onUpdate={actualizaPagosIsla}
                mostrarDenominaciones={mostrarDenominaciones}
                screen={screen}
              />
            </Grid>

            {mostrarDenominaciones && (
              <Grid item xs={12} lg={6}>
                <DenominacionesPagos denominaciones={denominaciones} onUpdate={actualizaDenomsIsla} screen={screen} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PagosStep;

const usePagosStep = (data) => {
  const {
    isla,
    asignaIsla,
    screen,
    totales,
    diferencia,
    diferenciaAceites,
    desactivaStepBtn,
    activaStepBtn,
    pagosAceites,
  } = data;
  const { loading } = useSelector((state) => state.liquidaciones);
  const [denoms, setDenoms] = useState(isla.denominaciones ?? []);
  const [pagos, setPagos] = useState(isla.pagos ?? []);
  const [pagoVales, setPagoVales] = useState(0.0);

  const isLoading = loading.getTiposPago || loading.getDenominaciones;

  const mostrarDenominaciones = Boolean(GetConfiguration(variables.MostrarDenominacionesEnLiq)?.valor ?? false);
  const tipoPagoEfectivo = GetConfiguration(variables.TipoPagoEfectivo)?.valor;
  const faltanteSobrante = GetConfiguration(variables.FaltanteSobrante)?.valor;

  const cuadrarLiquidacion = () => {
    let indexFS = pagos.findIndex((p) => p.idTipoPago === faltanteSobrante);
    let tmpP = pagos.slice();
    let pagoFaltanteSobrante = tmpP[indexFS];
    pagoFaltanteSobrante.importe += diferencia;
    tmpP[indexFS] = pagoFaltanteSobrante;
    setPagos(tmpP);
  };

  const actualizaIsla = () => {
    asignaIsla({ ...isla, denominaciones: denoms, pagos: pagos });
    actualizaDiferenciaAceites();
  };

  const actualizaEfectivo = () => {
    if (mostrarDenominaciones) {
      if ((pagos?.length ?? 0) > 0) {
        const indexP = pagos.findIndex((p) => p.idTipoPago === tipoPagoEfectivo);
        let tmpP = pagos.slice();
        let pagoEfectivo = tmpP[indexP];
        pagoEfectivo.importe = denoms.reduce((a, b) => a + (b.importe ?? 0), 0);
        tmpP[indexP] = pagoEfectivo;
        setPagos(tmpP);
      }
    }
  };

  const actualizaDiferenciaAceites = () => {
    if ((pagos?.length ?? 0) > 0) {
      let tmpP = pagos.slice();
      const pagosTPAceites = tmpP.filter((item) => item.clase >= 12 && item.clase <= 16);
      const totalPagosAceites = pagosTPAceites.reduce((sum, item) => sum + item.importe, 0);
      pagosAceites(totalPagosAceites);
    }
  };

  const actualizaPagosVales = () => {
    if ((pagos?.length ?? 0) > 0) {
      const indexP = pagos.findIndex((p) => p.clase === 6);
      let tmpP = pagos.slice();
      let pagoVale = tmpP[indexP];
      pagoVale.importe = pagoVales ?? 0;
      tmpP[indexP] = pagoVale;
      setPagos(tmpP);
    }
  };

  useEffect(actualizaIsla, [pagos]);
  useEffect(actualizaEfectivo, [denoms]);
  useEffect(actualizaPagosVales, [pagoVales]);
  useEffect(() => {
    isLoading ? desactivaStepBtn() : activaStepBtn();
  }, [isLoading]);

  return {
    denominaciones: denoms ?? [],
    pagos: pagos ?? [],
    mostrarDenominaciones,
    isLoading,
    totales,
    diferencia,
    diferenciaAceites,
    screen,
    faltanteSobrante,
    idIsla: data.isla.id,
    idTurno: data.turno.id,
    actualizaDenomsIsla: (lst) => setDenoms(lst),
    actualizaPagosIsla: (lst) => setPagos(lst),
    actualizaPagoVales: (x) => setPagoVales(x),
    cuadrarLiquidacion,
  };
};
