import { Box, Card, CardContent, Grid, Skeleton, Typography } from "@mui/material";
import React from "react";
import { InfoCard, InfoDetailCard, InfoTitle } from "../../igas";
import { decimalVal, formatDateTimeMX, monedaMX, zeroPad } from "@paul-igas/igas-react-hooks";
import { useWidth } from "../../../hooks";

export const TurnoInfo = (props) => {
  const { loading, turno, valesCredito } = props;
  const screen = useWidth();
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <InfoCard
            loading={loading}
            title={<InfoTitle caption='Turno' />}
            info={<InfoTitle color='primary' variant='h3' caption={zeroPad(turno?.noTurno, 2)} />}
            detail={
              <>
                <InfoDetailCard
                  title={<InfoTitle variant='body2' caption='Fecha:' bold />}
                  value={<InfoTitle variant='body2' caption={formatDateTimeMX(turno?.fecha ?? new Date())} />}
                />
                <InfoDetailCard
                  title={<InfoTitle variant='body2' caption='Cajero:' bold />}
                  value={<InfoTitle variant='body2' caption={turno?.nombreUsuario ?? ""} />}
                />
              </>
            }
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <InfoCard
            loading={loading.getByIdTurno}
            title={<InfoTitle caption='Volumen total' />}
            info={
              <InfoTitle
                color='primary'
                variant='h3'
                caption={decimalVal(3).format(valesCredito?.reduce((a, b) => a + b.volumen, 0))}
              />
            }
            detail={null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <InfoCard
            loading={loading}
            title={<InfoTitle caption='Importe total' />}
            info={
              <InfoTitle
                color='primary'
                variant='h3'
                caption={monedaMX.format(valesCredito?.reduce((a, b) => a + b.importe, 0))}
              />
            }
            detail={null}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TurnoInfo;
