import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, LinearProgress } from "@mui/material";
import { SimpleTable, ColumnType, ColumnAlign, ColumnPriority } from "@paul-igas/igas-react-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { resumenValesCombustibles, resumenValesIsla } from "../../../../store/operaciones/valescredito";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Combustible",
      name: "nombreCombustible",
      colType: ColumnType.String,
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.Normal,
    },
    {
      text: "Litros",
      name: "litros",
      colType: ColumnType.Decimal,
      colAlign: ColumnAlign.Right,
      colPriority: ColumnPriority.Normal,
      digitos:3,
    },
    {
      text: "Importe",
      name: "importe",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
      colPriority: ColumnPriority.Normal,
    },
  ],
};

const TABLE_DEFAULT2 = {
  columns: [
    {
      text: "Isla",
      name: "noIsla",
      colType: ColumnType.DataKey,
      colAlign: ColumnAlign.Left,
      colPriority: ColumnPriority.Low,
      digitos: 2,
    },
    {
      text: "Importe",
      name: "importe",
      colType: ColumnType.Money,
      colAlign: ColumnAlign.Right,
      colPriority: ColumnPriority.High,
    },
  ],
};

const TABLE_TOTALS = [
  {
    name: "action",
    colAlign: ColumnAlign.Left,
    isLabel: true,
    total: true,
  },
  { name: "litros", colType: ColumnType.Decimal, colAlign: ColumnAlign.Right, total: true, digitos: 3 },
  { name: "importe", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
];

const TABLE_TOTALS2 = [
  {
    name: "action",
    colAlign: ColumnAlign.Left,
    isLabel: true,
    total: true,
  },
  { name: "importe", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
];

export function ResumenValesDialog({ open, onClose, turno }) {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.valesCredito);
  const [combustibles, setCombustibles] = useState();
  const [totalCombustibles, setTotalCombustibles] = useState([]);
  const [totalIslas, setTotalIslas] = useState([]);
  const [islas, setIslas] = useState();
  const isLoading = loading.getResumenValesCombustibles || loading.getResumenValesIsla;

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const handleOpen = () => {
    setCombustibles([]);
    setIslas([]);
    if (open) {
      dispatch(resumenValesCombustibles(turno.id))
        .then(hasDispatchError)
        .then(asignaCombustibles)
        .catch(onClose);
      dispatch(resumenValesIsla(turno.id))
        .then(hasDispatchError)
        .then(asignaIslas)
        .catch(onClose);
    }
  };

  const asignaCombustibles = ({ payload }) => {
    setCombustibles(payload);
    if (payload)
    {
      setTotalCombustibles(
        [
          {
            litros: payload.reduce((a, b) => a + b.litros, 0),
            importe: payload.reduce((a, b) => a + b.importe, 0),
          }
        ]
      );
    }
  };

  const asignaIslas = ({ payload }) => {
    setIslas(payload);
    if (payload)
    {
      setTotalIslas(
        [
          {
            importe: payload.reduce((a, b) => a + b.importe, 0),
          }
        ]
      );
    }
  };

  useEffect(handleOpen, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        {isLoading && <LinearProgress />}

        <DialogTitle>Resumen de vales de crédito</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            Resumen de vales por tipo de combustible
          </DialogContentText>
          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={12}>
              <SimpleTable
                table={TABLE_DEFAULT}
                data={combustibles}
                loading={loading.getResumenValesCombustibles}
                showPagination={false}
                rowTotals={{ columns: TABLE_TOTALS, data: totalCombustibles }}
              />
            </Grid>
          </Grid>
          <DialogContentText>
            Resumen de vales por isla
          </DialogContentText>
          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={8}>
              <SimpleTable
                table={TABLE_DEFAULT2}
                data={islas}
                loading={loading.getResumenValesIsla}
                showPagination={false}
                rowTotals={{ columns: TABLE_TOTALS2, data: totalIslas }}
              />
            </Grid>
          </Grid>          
        </DialogContent>
        <DialogActions>
          <Button
            color='secondary'
            disableElevation
            onClick={handleClose}
            disabled={isLoading}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
