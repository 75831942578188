import { Grid, Typography } from "@mui/material";
import React from "react";
import { boldText } from "../../../helpers/utils";
import { decimalVal, monedaMX } from "@paul-igas/igas-react-hooks";

export const TotalesDialog = ({ totales }) => {
  return (
    <>
      <Grid container item xs={12} spacing={3}>
        <Grid container item xs={12} md={5} justifyContent='flex-start'>
          <Typography variant='subtitle1' display='block'>
            {boldText(`Vales: ${totales.cantidadVales}`)}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent='flex-end'>
          <Typography variant='subtitle1' display='block'>
            {boldText("Totales: ")}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={2} justifyContent='flex-start'>
          <Typography variant='subtitle1' display='block'>
            {boldText(decimalVal(3).format(totales.volumen))}
          </Typography>
        </Grid>
        <Grid container item xs={12} md={2} justifyContent='flex-start'>
          <Typography variant='subtitle1' display='block'>
            {boldText(monedaMX.format(totales.importe))}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default TotalesDialog;
