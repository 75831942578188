import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Catalogo, ColumnType, DangerMessage, ExportDialog, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal, formatDateTimeUS } from "@paul-igas/igas-react-hooks";
import {
  clearErrors,
  getAgrupadoFecha,
  getCajeros,
  getUltimo,
  setFilter,
  setTurno,
} from "../../store/operaciones/turnos";
import {
  CloseDialog,
  DateRow,
  DeleteDialog,
  FilterDrawer,
  InsertDialog,
  OpenDialog,
  ValesFacturados,
  ValesPendientesExportar,
  ValesSinLiquidar,
} from "../../components/operaciones/turnos";
import { TurnoContext } from "../../contexts";
import { hasDispatchError } from "../../api/services/service";
import { validRoles } from "../../components/helpers/user";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Fecha Turno",
      name: "fecha",
      colType: ColumnType.Date,
      renderGroup: (value) => <DateRow data={value} />,
    },
  ],
};

export function Turnos() {
  const dispatch = useDispatch();
  const { turnos, loading, filter, error } = useSelector((state) => state.turnos);
  const { tienePermiso } = GetInfo();
  const [openGroupDate, setOpenGroupDate] = useState([]);
  const [openGroupFactura, setOpenGroupFactura] = useState([]);
  const [ultimo, setUltimo] = useState({});
  const [turnoExportarVales, setTurnoExportarVales] = useState({});
  const [valesFacturados, setValesFacturados] = useState([]);
  const [open, setOpen] = useState(true);
  const modalInsert = useModal(false);
  const modalExportarVales = useModal(false);
  const modalValesSinLiquidar = useModal(false);
  const modalValesFacturados = useModal(false);
  const modalOpen = useModal(false);
  const modalClose = useModal(false);
  const modalDelete = useModal(false);
  const modalExport = useModal(false);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const context = {
    ultimo,
    modalOpen,
    modalClose,
    modalDelete,
    openGroupDate,
    openGroupFactura,
    setOpenGroupDate,
    setTurnoExportarVales,
    setOpenGroupFactura,
    setTurno,
    modalExport,
    modalExportarVales,
    turnoExportarVales,
    modalValesFacturados,
    valesFacturados,
    setValesFacturados,
  };

  const asignaUltimoTurno = ({ payload }) => {
    setUltimo(payload);
  };

  const initFilter = ({ payload }) => {
    if (open) {
      if (filter.fechaIni.value === "") {
        let dteIni = new Date();
        let dteFin = new Date();

        const defaultFilter = {
          ...filter,
          fechaIni: {
            ...filter.fechaIni,
            state: true,
            value: formatDateTimeUS(dteIni.setDate(dteFin.getDate() - 1)),
          },
          fechaFin: {
            ...filter.fechaFin,
            state: true,
            value: formatDateTimeUS(dteFin),
          },
          idUsuario: {
            ...filter.idUsuario,
            state: false,
            value: payload?.length > 0 ? payload[0]?.id : "",
          },
        };

        dispatch(setFilter(defaultFilter));
      }
      setOpen(false);
    }
  };

  const refreshParams = () => {
    dispatch(getCajeros()).then(initFilter);
  };

  const handleRefresh = (_) => {
    if (filter.fechaIni.value !== "") {
      dispatch(getUltimo())
        .then(hasDispatchError)
        .then(asignaUltimoTurno)
        .catch(() => {});
      dispatch(getAgrupadoFecha(filter))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && warningMsg.open();
      error?.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(refreshParams, []);
  useEffect(handleError, [error]);

  return (
    <TurnoContext.Provider value={context}>
      <Catalogo
        title='Administrar turnos'
        table={TABLE_DEFAULT}
        data={turnos}
        loading={loading.getAgrupadoFecha}
        onRefresh={handleRefresh}
        onInsert={tienePermiso ? modalInsert.open : null}
        disableHead
        showEmptyRows={false}
        showSearch={false}
        keyColumn='fecha'
        order='desc'
        orderBy='fecha'
        filter={filter}
        onRefreshFilter={refreshParams}
        filterPanel={<FilterDrawer />}
        showFilterData={false}
      />

      <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
      <OpenDialog
        open={modalOpen.isShowing}
        onClose={modalOpen.close}
        postSubmit={handleRefresh}
        lastTurno={context.ultimo}       
      />
      <CloseDialog
        open={modalClose.isShowing}
        onClose={modalClose.close}
        postSubmit={handleRefresh}
        valesSinLiquidar={modalValesSinLiquidar.open}
      />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />

      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <ExportDialog open={modalExport.isShowing} onClose={modalExport.close} />
      <ValesPendientesExportar
        open={modalExportarVales.isShowing}
        onClose={modalExportarVales.close}
        postSubmit={handleRefresh}
        turno={turnoExportarVales}
      />
      <ValesSinLiquidar open={modalValesSinLiquidar.isShowing} onClose={modalValesSinLiquidar.close} />
      <ValesFacturados
        open={modalValesFacturados.isShowing}
        onClose={modalValesFacturados.close}
        valesFacturados={valesFacturados}
      />
    </TurnoContext.Provider>
  );
}

export default Turnos;

const GetInfo = () => {
  const { profile } = useSelector((state) => state.aplicacion);

  return {
    tienePermiso: validRoles(profile.role, "Cajero"),
  };
};
