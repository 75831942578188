import { useModal, formatDateTimeMX, zeroPad } from "@paul-igas/igas-react-hooks";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { putOpen } from "../../../../store/operaciones/turnos";
import { hasDispatchError } from "../../../../api/services/service";
import { getValesFacturados } from "../../../../store/operaciones/valescredito";
import { useEffect, useState } from "react";
import { UseTurnoContext } from "../../../../contexts";
import { boldText } from "../../../helpers/utils";

export function OpenDialog({ open, onClose, postSubmit, lastTurno }) {
  const dispatch = useDispatch();
  const { loading, turno } = useSelector((state) => state.turnos);

  const { modalValesFacturados, setValesFacturados } = UseTurnoContext();
  const successMsg = useModal(false);
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
    setValesFacturados([]);
  };
  const valesCredito = useValesCredito(handleClose);

  const onSubmit = () => {
    if (valesCredito.valesFacturados.length > 0) {
      setValesFacturados(valesCredito.valesFacturados);
      onClose();
      modalValesFacturados.open();
      return;
    }
    
    dispatch(putOpen({ id: turno.id }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const init = () => {
    if ((turno?.id ?? 0) > 0) {
      valesCredito.obtieneValesFacturados(turno);
    }
  };

  useEffect(init, [turno]);
  useEffect(() => {
    if (open) {
      init();
    }
  }, [open]);

  if (!Date.parse(turno.fecha)) return null;

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        {loading.setAbrir && <LinearProgress />}
        <DialogTitle>Abrir turno</DialogTitle>
        {valesCredito.loading.getValesFacturados && <LinearProgress />}
        {!valesCredito.loading.getValesFacturados && (
          <DialogContent dividers>
            <DialogContentText>
              ¿Desea abrir turno {boldText(zeroPad(turno?.noTurno, 2))} del día{" "}
              {boldText(formatDateTimeMX(turno?.fecha))}?
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.setAbrir || valesCredito.loading.getValesFacturados}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.setAbrir || valesCredito.loading.getValesFacturados}
            onClick={onSubmit}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se abrió correctamente.' />
    </>
  );
}

const useValesCredito = (handleClose) => {
  const dispatch = useDispatch();
  const { valesFacturados, loading } = useSelector((state) => state.valesCredito);
  const getValesFacturadosByTurno = (turno) => {
    if (turno !== 0) {
      dispatch(getValesFacturados(turno))
        .then(hasDispatchError)
        .catch(() => {
          handleClose();
        });
    }
  };

  return {
    obtieneValesFacturados: (t) => getValesFacturadosByTurno(t),
    valesFacturados,
    loading,
  };
};
