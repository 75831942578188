import { Button, Grid, Tooltip } from "@mui/material";
import { ExportDialog, IGasForm, IGasNumberField, WarningMessage } from "@paul-igas/igas-react-components";
import { getModelErrors, useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { AddCircle } from "@mui/icons-material";
import { getFolioVolumetricoById } from "../../../../store/consultas/foliosvolumetricos";

export const NewFolioVol = ({ existeEnMemoria, handleNewFolio, open = false }) => {
  const { error } = useSelector((state) => state.foliosVolumetricos);
  const { loading } = useSelector((state) => state.valesCredito);

  const dispatch = useDispatch();
  const modalExport = useModal(false);
  const warningMsg = useModal(false);
  const [errors, setErrors] = useState({});

  function InitDialog() {
    if (open) {
      handleCloseError();
    }
  }

  const validate = (data) => {
    if (data.folioVolumetrico === "") {
      setErrors({ folioVolumetrico: "Folio volumétrico es requerido." });
      return false;
    }

    if (existeEnMemoria(data.folioVolumetrico)) {
      setErrors({ folioVolumetrico: "Folio volumétrico ya existe en el listado." });
      return false;
    }
    return true;
  };

  const onSubmitFolioVol = (data) => {
    if (!validate(data)) return;
    setErrors({});
    modalExport.open();
    dispatch(getFolioVolumetricoById(data.folioVolumetrico))
      .then(hasDispatchError)
      .then(({ payload }) => handleNewFolio(payload))
      .then(modalExport.close)
      .catch(modalExport.close);
  };
  const handleError = () => {
    if (Boolean(error.status)) {
      if (error?.status === 400) setErrors(getModelErrors(error.message));
      if (error?.status === 409) warningMsg.open();
    }
  };
  const handleCloseError = () => {
    warningMsg.close();
    setErrors({});
  };

  useEffect(handleError, [error]);

  useEffect(InitDialog, [open]);

  return (
    <IGasForm onSubmit={onSubmitFolioVol}>
      <Grid container direction='row-reverse'>
        <Grid xs={1} sx={{ ml: -3 }} alignContent='center' textAlign='right'>
          <Tooltip title='Añadir folio volumétrico'>
            <span>
              <Button style={{ minWidth: 0 }} size='small' type='submit' disableElevation disabled={loading.insert}>
                <AddCircle />
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid xs={4}>
          <IGasNumberField
            name='folioVolumetrico'
            label='Folio volumétrico'
            defaultValue='0'
            error={errors?.folioVolumetrico}
            disabled={loading.insert}
            autoFocus={true}
          />
        </Grid>
      </Grid>
      <WarningMessage open={warningMsg.isShowing} onClose={warningMsg.close} text={error?.message} />
      <ExportDialog
        open={modalExport.isShowing}
        onClose={modalExport.close}
        title='Validando folio volumétrico.'
        showPuedeTardar={false}
      />
    </IGasForm>
  );
};

export default NewFolioVol;
