import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { useEffect } from "react";
import { getById } from "../../../store/operaciones/valescredito";
import { ColumnAlign, ColumnType, DetailInfo, LoadingPanel, SimpleTable } from "@paul-igas/igas-react-components";
import { wait } from "../../helpers/utils";
import TurnoDialogInfo from "./components/turnoDialogInfo";
import TotalesDialog from "./components/totalesDialog";
import { formatDateTimeMX, zeroPad } from "@paul-igas/igas-react-hooks";

const TABLE_TICKETS = {
  columns: [
    { text: "Folio", name: "folio" },
    { text: "Isla", name: "isla", colType: ColumnType.DataKey, digitos: 2 },
    { text: "Combustible", name: "nombreCombustible" },
    { text: "Volumen", name: "volumen", colAlign: ColumnAlign.Right, colType: ColumnType.Decimal, digitos: 3 },
    { text: "Importe", name: "importe", colAlign: ColumnAlign.Right, colType: ColumnType.Money },
  ],
};

export const DetalleDialog = ({ open, onClose, postSubmit, turno }) => {
  const dispatch = useDispatch();
  const { loading, valeCreditoIdSelected } = useSelector((state) => state.valesCredito);
  const data = useDetalleValesCredito(open);

  const asignarDatos = ({ payload }) => {
    data.asignaCliente({ noClie: payload.noClie, nombre: payload.nombre });
    data.initialize(payload.valesCreditoDetalle);
  };

  const initDialog = () => {
    if (open) {
      dispatch(getById(valeCreditoIdSelected.id)).then(hasDispatchError).then(asignarDatos).catch(onClose);
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>Detalle de paquete de vales de crédito</DialogTitle>
        <DialogContent dividers>
          {loading.getById && <LoadingPanel />}
          {!loading.getById && (
            <Grid container spacing={2} alignContent={"space-between"}>
              <DialogContentText>
                Turno {zeroPad(turno.noTurno, 2)} del día {formatDateTimeMX(turno?.fecha ?? new Date())}
              </DialogContentText>
              <Grid item xs={12} sm={12} container justifyContent='space-between' direction='row' alignItems='center'>
                <Grid item xs={12} sm={12}>
                  <DetailInfo title='Cliente' description={data?.cliente?.nombre ?? ""} loading={data.refresh} />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} container justifyContent='space-between' direction='row' alignItems='center'>
                <Typography style={{ fontSize: "1rem", marginBottom: "10px" }}>Listado de vales</Typography>
                <Grid item xs={12}>
                  <SimpleTable
                    table={TABLE_TICKETS}
                    loading={data.refresh}
                    data={data?.listaVales ?? []}
                    showPagination={false}
                  />
                </Grid>
              </Grid>
              <Grid container item xs={12} justifyContent='flex-end' spacing={3}>
                <TotalesDialog totales={data.totales} />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation onClick={onClose}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetalleDialog;

const useDetalleValesCredito = (open) => {
  const [foliosVolumetricos, setFoliosVolumetricos] = useState([]);
  const [cliente, setCliente] = useState({ claveCliente: 0 });
  const [refresh, setRefresh] = useState(false);

  const [totales, setTotales] = useState({ importe: 0, volumen: 0, cantidadVales: 0 });

  const actualizaTotales = () => {
    setTotales({
      volumen: foliosVolumetricos?.reduce((a, b) => a + b.volumen, 0),
      importe: foliosVolumetricos?.reduce((a, b) => a + b.importe, 0),
      cantidadVales : foliosVolumetricos.length
    });
  };

  const refreshValesDetalle = async () => {
    if (foliosVolumetricos.length > 0) {
      setRefresh(true);
      await wait(1000);
    }
    setRefresh(false);
  };
  const initValesDetalle = () => {
    if (open === true) setFoliosVolumetricos([]);
  };

  useEffect(initValesDetalle, [open]);
  useEffect(() => {
    refreshValesDetalle();
    actualizaTotales();
  }, [foliosVolumetricos]);

  return {
    cliente,
    listaVales: foliosVolumetricos,
    open,
    totales,
    asignaCliente: (c) => setCliente(c),
    initialize: (foliosVolumetricos) => setFoliosVolumetricos(foliosVolumetricos),
    refresh,
  };
};
