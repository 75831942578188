import api from "../service";

const _controller = "valeCredito/FoliosVolumetricos/"

export async function GetFolioVolumetricoById(params) {
    const res = await api.get(`${_controller}${params}`);
    return res.data;
  }
  
  export default {
    GetFolioVolumetricoById,
  };
  