import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetClientesEstacion } from "../../api/services/consultas/clientes";

export const selectClientes = createAsyncThunk("clientes/selectClientes", async (params, { rejectWithValue }) => {
  try {
    return await GetClientesEstacion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  clientes: [],
  error: {},
  loading: {
    getClientes: false,
  },
};

const slice = createSlice({
  name: "clientes",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
    setClientesList: (state, action) => {
      state.clientes = action.payload;
    },
  },
  extraReducers: (builder) => {
    // selectClientes
    builder.addCase(selectClientes.pending, (state) => {
      state.loading.getClientes = true;
    });
    builder.addCase(selectClientes.fulfilled, (state, action) => {
      state.clientes = action.payload;
      state.error = {};
      state.loading.getClientes = false;
    });
    builder.addCase(selectClientes.rejected, (state, action) => {
      state.error = action.payload;
      state.clientes = [];
      state.loading.getClientes = false;
    });
  },
});

export default slice.reducer;
export const { clearErrors, setClientesList } = slice.actions;
