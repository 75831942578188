import { useState } from "react";
import { ExpandLess, ExpandMore, LockOpen } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { UseTurnoContext, UseTurnoTheme } from "../../../contexts";

const gridRoot = {
  borderTopStyle: "solid",
  borderWidth: 1,
  borderColor: "lightgray",
};

export const ValesFacturadosRow = ({ data }) => {
  const { openGroupFactura, setOpenGroupFactura } = UseTurnoContext();
  const theme = UseTurnoTheme();
  const [more, setMore] = useState(false);

  function handleClickMore() {
    if (more) {
      setOpenGroupFactura(openGroupFactura.filter((group) => group !== data.folioFactura));
      setMore(false);
    } else {
      setOpenGroupFactura([...openGroupFactura, data.folioFactura]);
      setMore(true);
    }
  }

  return (
    <Grid container wrap='nowrap' spacing={2} alignItems='center'>
      <Grid item>
        <Button size='small' onClick={handleClickMore} style={{ minWidth: 0 }}>
          {more ? (
            <ExpandLess color='action' style={{ fontSize: 22 }} />
          ) : (
            <ExpandMore color='action' style={{ fontSize: 22 }} />
          )}
        </Button>
      </Grid>

      <Grid item container direction='column'>
        <Grid item>
          <Typography
            variant='subtitle2'
            gutterBottom
            style={{
              marginTop: theme.spacing(0.5),
              marginRight: theme.spacing(0.5),
              fontWeight: "bold",
            }}
          >
            {data.folioFactura}
          </Typography>
        </Grid>

        {more && (
          <Box>
            <Grid container style={{ fontWeight: "bold", borderBottom: "1px solid lightgray" }}>
              <Grid item xs={2.4} align='center'>
                Folio
              </Grid>
              <Grid item xs={2.4} align='center'>
                Volumen
              </Grid>
              <Grid item xs={2.4} align='center'>
                Isla
              </Grid>
              <Grid item xs={2.4} align='center'>
                Importe
              </Grid>
              <Grid item xs={2.4} align='center'>
                Combustible
              </Grid>
            </Grid>
            {data.vales.map((vale) => (
              <ValeRow key={vale.id} vale={vale} />
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default ValesFacturadosRow;

const ValeRow = ({ vale }) => {
  return (
    <Grid container sx={gridRoot} alignItems='center'>
      <Grid item xs={2.4} align='center'>
        {vale.folio}
      </Grid>
      <Grid item xs={2.4} align='center'>
        {vale.volumen}
      </Grid>
      <Grid item xs={2.4} align='center'>
        {vale.isla}
      </Grid>
      <Grid item xs={2.4} align='center'>
        {`$${vale.importe}`}
      </Grid>
      <Grid item xs={2.4} align='center'>
        {vale.combustible}
      </Grid>
    </Grid>
  );
};
