import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFolioVolumetricoById } from "../../api/services/consultas/foliosvolumetricos";
import { hasErrorRedux } from "../../api/services/service";

export const getFolioVolumetricoById = createAsyncThunk(
  "foliosvolumetricos/foliovolumetrico",
  async (params, { rejectWithValue }) => {
    try {
      return await GetFolioVolumetricoById(params);
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

const initialState = {
  error: {},
};

const slice = createSlice({
  name: "foliosVolumetricos",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFolioVolumetricoById.fulfilled, (state, action) => {
      state.error = {};
    });
    builder.addCase(getFolioVolumetricoById.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default slice.reducer;
export const { clearErrors } = slice.actions;
