import { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid } from "@mui/material";
import {
  ColumnAlign,
  ColumnType,
  DangerMessage,
  LoadingPanel,
  SimpleTable,
  SuccessMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { hasDispatchError } from "../../../../api/services/service";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  exportarValesPendientes,
  selectNoExportadosByIdTurno,
} from "../../../../store/operaciones/valescredito";
import { useModal } from "@paul-igas/igas-react-hooks";

const TABLE_TICKETS = {
  columns: [
    { text: "Folio", name: "folio" },
    { text: "Isla", name: "isla", colType: ColumnType.DataKey, digitos: 2 },
    { text: "Volumen", name: "volumen", colAlign: ColumnAlign.Right, colType: ColumnType.Decimal, digitos: 3 },
    { text: "Importe", name: "importe", colAlign: ColumnAlign.Right, colType: ColumnType.Money },
  ],
};

export const ValesPendientesExportar = ({ open, onClose, postSubmit, turno }) => {
  const dispatch = useDispatch();
  const { loading, valesCreditoNoExportados, error } = useSelector((state) => state.valesCredito);

  const successMsg = useModal(false);
  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    postSubmit();
    onClose();
  };

  const initDialog = () => {
    if (open) {
      dispatch(selectNoExportadosByIdTurno(turno.id)).then(hasDispatchError).catch(onClose);
    }
  };

  const handleExportarVales = () => {
    dispatch(exportarValesPendientes(turno))
      .then(hasDispatchError)
      .then(successMsg.open)
      .then(initDialog)
      .catch(() => {});
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  useEffect(handleError, [error]);
  useEffect(initDialog, [open]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Vales pendientes de exportar</DialogTitle>
        {loading.getNoExportadosByIdTurno && <LoadingPanel />}
        {!loading.getNoExportadosByIdTurno && (
          <DialogContent dividers>
            <Grid item xs={12}>
              <SimpleTable
                table={TABLE_TICKETS}
                loading={loading.getNoExportadosByIdTurno}
                data={valesCreditoNoExportados ?? []}
                showPagination={false}
              />
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button
            color='secondary'
            disableElevation
            onClick={handleExportarVales}
            disabled={loading.exportarValesPendientes || (valesCreditoNoExportados?.length ?? 0) === 0}
          >
            Exportar
          </Button>
          <Button color='secondary' disableElevation onClick={handleClose} disabled={loading.exportarValesPendientes}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Vales exportados correctamente.' />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
};

export default ValesPendientesExportar;
