import { Grid, IconButton, Tooltip } from "@mui/material";
import NewFolioVol from "./newFolioVol";
import { ColumnAlign, ColumnType, SimpleTable } from "@paul-igas/igas-react-components";
import { RemoveCircle } from "@mui/icons-material";

const TABLE_VALES = {
  columns: [
    { text: "Folio", name: "folio" },
    { text: "Isla", name: "isla", colType: ColumnType.DataKey, digitos: 2 },
    { text: "Combustible", name: "nombreCombustible" },
    { text: "Volumen", name: "volumen", colAlign: ColumnAlign.Right, colType: ColumnType.Decimal, digitos: 3 },
    { text: "Importe", name: "importe", colAlign: ColumnAlign.Right, colType: ColumnType.Money },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};


export const ListaValesCredito = ({ data }) => {
  const { listaVales, actualizaVales, open, refresh } = data;
  const validateFolioVol = (_folio) => {
    let bandera = listaVales.some((folioVol) => folioVol.folio == _folio);
    return bandera;
  };

  const onSubmitDeleteFolio = (updateFolios) => {
    actualizaVales(updateFolios);
  }; 

  return (
    <Grid container spacing={2}>
      <Grid item sm={12}>
        <NewFolioVol
          existeEnMemoria={validateFolioVol}
          handleNewFolio={(folio) => {
            actualizaVales([...listaVales, folio]);
          }}
          open={open}
        />
      </Grid>

      <Grid item sm={12} style={{ maxHeight: "150px", overflow: "auto" }}>
        <SimpleTable
          table={TABLE_VALES}
          data={listaVales}
          showPagination={false}
          loading={refresh}
          rowOptions={<Options folios={listaVales} onSubmitDeleteFolio={onSubmitDeleteFolio} />}
        />
      </Grid>
    </Grid>
  );
};

export default ListaValesCredito;

function Options(props) {
  const { folios = {}, onSubmitDeleteFolio = () => {}, loadingUpdate } = props;
  const onDeleteFolio = (datos) => {
    let array = folios.filter((folio) => folio.folio !== datos.folio);
    onSubmitDeleteFolio(array);
  };

  if (props.data.liquidado || props.data.exportado || props.data.facturado) return null;

  return (
    <>
      <IconButton size='small' color='error' onClick={() => onDeleteFolio(props.data)}>
        <Tooltip title='Eliminar folio'>
          <RemoveCircle />
        </Tooltip>
      </IconButton>
    </>
  );
}
