import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import aplicacion from "./aplicacion";
import turnos from "./operaciones/turnos";
import liquidaciones from "./operaciones/liquidaciones";
import preliquidaciones from "./operaciones/preliquidaciones";
import reportesLiq from "./reportes/reportesLiq";
import usuarios from "./consultas/usuarios";
import dashboard from "./consultas/dashboard";
import valesCredito from "./operaciones/valescredito";
import foliosVolumetricos from "./consultas/foliosvolumetricos";
import clientes from "./consultas/clientes";

const reducer = combineReducers({
  aplicacion,
  turnos,
  liquidaciones,
  preliquidaciones,
  reportesLiq,
  usuarios,
  dashboard,
  valesCredito,
  foliosVolumetricos,
  clientes
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
