import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import { ExportDialog, SuccessMessage } from "@paul-igas/igas-react-components";
import { formatDateTimeMX, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../../api/services/service";
import { putClose } from "../../../../store/operaciones/turnos";
import { boldText } from "../../../helpers/utils";
import { getDiferenciaValesTurno } from "../../../../store/operaciones/valescredito";
import { useEffect, useState } from "react";

export function CloseDialog({ open, onClose, postSubmit, valesSinLiquidar }) {
  const dispatch = useDispatch();
  const { loading, turno } = useSelector((state) => state.turnos);
  const valesCredito = useValesCredito();

  const modalClosing = useModal(false);
  const successMsg = useModal(false);

  const onSubmit = () => {
    if (!valesCredito.esCorrecto) {
      onClose();
      valesSinLiquidar();
      return;
    }

    modalClosing.open();
    dispatch(putClose({ id: turno.id }))
      .then(hasDispatchError)
      .then(onClose)
      .then(modalClosing.close)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {
        onClose();
        modalClosing.close();
      });
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const init = () => {
    if ((turno?.id ?? 0) > 0) {
      valesCredito.obtieneDiferenciaVales(turno.id);
    }
  };

  useEffect(init, [turno]);
  useEffect(() => {
    if (open) {
      init();
    }
  }, [open]);

  if (!Date.parse(turno.fecha)) return null;

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        {loading.setCerrar && <LinearProgress />}

        <DialogTitle>Cerrar turno</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            ¿Desea cerrar turno {boldText(zeroPad(turno?.noTurno, 2))} del día{" "}
            {boldText(formatDateTimeMX(turno?.fecha))}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.setCerrar} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.setCerrar} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      <ExportDialog open={modalClosing.isShowing} onClose={modalClosing.close} title='Validando cierre de turno' />
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se cerró correctamente.' />
    </>
  );
}

const useValesCredito = () => {
  const dispatch = useDispatch();
  const [diferencia, setDiferencia] = useState(0);

  const calculaDiferenciaVales = (turno) => {
    if (turno > 0) {
      dispatch(getDiferenciaValesTurno(turno))
        .then(hasDispatchError)
        .then(({ payload }) => setDiferencia(Math.abs(payload)))
        .catch(() => {});
    }
  };

  return {
    esCorrecto: diferencia === 0,
    obtieneDiferenciaVales: (t) => calculaDiferenciaVales(t),
  };
};
