import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ListaValesCredito from "./components/listaValesCredito";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/operaciones/valescredito";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { formatDateTimeMX, getModelErrors, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import ConsultarClientes from "./dialogs/consultarClientes";
import TurnoDialogInfo from "./components/turnoDialogInfo";
import TotalesDialog from "./components/totalesDialog";

export const InsertDialog = ({ open, onClose, postSubmit, turno }) => {
  const dispatch = useDispatch();
  const data = useInsertValesCredito(open);
  const { loading, error } = useSelector((state) => state.valesCredito);
  const [errors, setErrors] = useState([]);

  const successMsg = useModal(false);
  const consultarClienteDlg = useModal(false);

  const asignarCliente = (payload) => {
    data.asignaCliente(payload);
  };

  function InitDialog() {
    setErrors({});
    if (open) {
      data.asignaCliente({});
      data.actualizaVales([]);
    }
  }

  const onSubmit = (event) => {
    event.preventDefault();
    const modeloPaquete = {
      nombreCliente: data?.cliente?.nombre ?? "",
      claveCliente: data?.cliente?.noClie ?? 0,
      fechaTurno: turno.fecha,
      idTurno: turno.id,
      valeCreditoDetalle: [...(data?.listaVales ?? [])],
    };
    setErrors({});
    dispatch(insert(modeloPaquete))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setErrors({});
    onClose();
  };

  const handleError = () => {
    if (open) {
      if (error?.status === 400) setErrors(getModelErrors(error.message));
    }
  };

  useEffect(handleError, [error]);
  useEffect(InitDialog, [open]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='md' scroll='paper'>
        <DialogTitle>Agregar paquete de vales de crédito</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} alignContent={"space-between"}>
            <DialogContentText>
              Turno {zeroPad(turno.noTurno, 2)} del día {formatDateTimeMX(turno?.fecha ?? new Date())}
            </DialogContentText>
            <Grid item xs={12} sm={12} container sx={{ pt: 2 }}>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  label='Cliente'
                  variant='standard'
                  value={data?.cliente.nombre ?? ""}
                  helperText={errors?.nombreCliente}
                  FormHelperTextProps={{
                    style: { color: "red" },
                  }}
                  aria-readonly={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={consultarClienteDlg.open} edge='end' disabled={loading.insert}>
                          <Tooltip title='Seleccionar cliente'>
                            <Search />
                          </Tooltip>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Card variant='outlined' style={{ border: "none" }}>
                <CardHeader style={{ textAlign: "center" }} subheader='Listado de vales' />
                <CardContent sx={{ mt: -3 }}>
                  <ListaValesCredito data={data} />
                  {Boolean(errors?.valeCreditoDetalle) && (
                    <Typography
                      variant='caption'
                      marginLeft={2}
                      style={{ color: "red" }}
                    >{`${errors?.valeCreditoDetalle}`}</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid container item xs={12} justifyContent='flex-end' spacing={2} sx={{ pr: 6 }}>
              <TotalesDialog totales={data.totales} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.insert} type='button' onClick={onSubmit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text='Paquete de vales se registró correctamente'
      />
      <ConsultarClientes
        open={consultarClienteDlg.isShowing}
        onClose={consultarClienteDlg.close}
        postSubmit={asignarCliente}
      />
    </>
  );
};

const useInsertValesCredito = (open) => {
  const [foliosVolumetricos, setFoliosVolumetricos] = useState([]);
  const [cliente, setCliente] = useState({ claveCliente: 0 });
  const [totales, setTotales] = useState({ importe: 0, volumen: 0, cantidadVales: 0  });
  const actualizaTotales = () => {
    setTotales({
      volumen: foliosVolumetricos?.reduce((a, b) => a + b.volumen, 0),
      importe: foliosVolumetricos?.reduce((a, b) => a + b.importe, 0),
      cantidadVales : foliosVolumetricos.length
    });
  };

  useEffect(actualizaTotales, [foliosVolumetricos]);
  return {
    cliente,
    listaVales: foliosVolumetricos,
    open,
    totales,
    actualizaVales: setFoliosVolumetricos,
    asignaCliente: (c) => setCliente(c),
  };
};
