import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { CancelDialog, InfoPanel, StepperManager } from "../../../components/operaciones/liquidacion";
import {
  CombustibleGlobalStep,
  GeneralStep,
  PagosStep,
  ProductosGlobalStep,
  ResumenStep,
} from "../../../components/operaciones/liquidacion/steps";
import { useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";
import { clearErrors, insert, preview, setLecturaProductoModificado } from "../../../store/operaciones/liquidaciones";
import { DangerMessage, LoadingPanel, WarningMessage } from "@paul-igas/igas-react-components";
import { showInWindow } from "../../../components/helpers/documents";
import { GetConfiguration } from "../../../components/helpers/variablesEstacion";
import { getUltimo } from "../../../store/operaciones/turnos";
import { useWidth } from "../../../hooks";

const steps = [
  { text: "Datos generales", textMobile: "Datos Gen" },
  { text: "Combustibles", textMobile: "Vtas Comb" },
  { text: "Productos diversos", textMobile: "Prod Diverso" },
  { text: "Pagos", textMobile: "Pagos" },
  { text: "Resumen", textMobile: "Resumen" },
];

export function AgregarLiquidacionGlobal() {
  const data = useAgregarLiquidacion();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.liquidaciones);

  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);
  const cancelarDlg = useModal(false);

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      {!data.saving && (
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={12} md={7} lg={9}>
            <Typography variant='h5' paragraph>
              Agregar liquidación global
            </Typography>
          </Grid>

          <Grid item xs>
            <StepperManager
              disableBackButton={data.disableBtns || data.step === 0}
              disableNextButton={data.validaBotonSiguiente()}
              onCancel={cancelarDlg.open}
              onReturn={data.anteriorStep}
              onContinue={data.siguienteStep}
            />
          </Grid>
        </Grid>
      )}

      <CancelDialog open={cancelarDlg.isShowing} onClose={cancelarDlg.close} />

      <WarningMessage
        open={data.liquidadaMsg.isShowing}
        onClose={data.liquidadaMsg.close}
        text='Isla se encuentra liquidada.'
      />

      <WarningMessage
        open={data.limiteVentasExcedidoMsg.isShowing}
        onClose={data.limiteVentasExcedidoMsg.close}
        text='Existen ventas que exceden el límite máximo permitido.'
      />

      {data.saving ? (
        <LoadingPanel title='Guardando liquidación...' size={100} />
      ) : (
        <>
          <Paper sx={{ mt: 1, mb: 1 }}>
            <Stepper activeStep={data.step} style={{ padding: 16 }} alternativeLabel>
              {steps.map((label) => (
                <Step key={label.text}>
                  <StepLabel>{data.screen === "xs" || data.screen === "sm" ? label.textMobile : label.text}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>

          {data.step < 4 && <InfoPanel data={data} />}
          {data.turnoCargado && data.step === 0 && <GeneralStep data={data} />}
          {data.turnoCargado && data.step === 1 && <CombustibleGlobalStep data={data} />}
          {data.turnoCargado && data.step === 2 && <ProductosGlobalStep data={data} />}
          {data.turnoCargado && data.step === 3 && <PagosStep data={data} />}
          {data.turnoCargado && data.step === 4 && <ResumenStep data={data} />}
        </>
      )}

      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export default AgregarLiquidacionGlobal;

const useAgregarLiquidacion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const screen = useWidth();
  const { loading, jarreoConsignacionModificados, lecturaProductoModificado } = useSelector(
    (state) => state.liquidaciones
  );
  const [step, setStep] = useState(0);
  const [turno, setTurno] = useState(null);
  const [lastTurno, setLastTurno] = useState({});
  const [isla, setIsla] = useState([]);
  const [despachador, setDespachador] = useState(null);
  const [disableBtns, setDisableBtns] = useState(true);
  const [saving, setSaving] = useState(false);
  const [liquidada, setLiquidada] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [pagosAceites, setPagosAceites] = useState(0.0);
  const liquidadaMsg = useModal(false);
  const limiteVentasExcedidoMsg = useModal(false);
  const LIQGLOBAL = true;

  const LIMITEMAXPERMITIDO = GetConfiguration(4)?.valor;

  const asignaLastTurno = ({ payload }) => {
    setLastTurno(payload);
  };

  const initIsla = () => {
    setIsla({ isla: 0 });
    dispatch(getUltimo())
      .then(hasDispatchError)
      .then(asignaLastTurno)
      .catch(() => {});
    dispatch(setLecturaProductoModificado(false));
  };

  const totales = {
    combustibles: isla?.lecturas?.reduce((a, b) => a + b.importe, 0) ?? 0,
    productos: isla?.productos?.reduce((a, b) => a + b.importe, 0) ?? 0,
    pagos: isla?.pagos?.reduce((a, b) => a + (b.importe ?? 0), 0) ?? 0,
    denominaciones: isla?.denominaciones?.reduce((a, b) => a + b.venta, 0) ?? 0,
  };

  const diferencia = round(totales.combustibles, 2) + round(totales.productos, 2) - round(totales.pagos, 2);
  const diferenciaAceites = round(round(totales.productos, 2) - round(pagosAceites, 2), 2);

  const modeloLiquidacion = {
    idTurno: turno?.id,
    noTurno: turno?.noTurno,
    fechaHora: turno?.fecha,
    idIsla: isla?.id,
    noIsla: isla?.isla,
    idDespachador: despachador?.id,
    nombreDespachador: despachador?.nombreCompleto,
    totalCombustible: totales.combustibles,
    totalDiversos: totales.productos,
    totalGeneral: totales.productos + totales.combustibles,
    totalPagado: totales.pagos,
    lecturasCombustibles: isla?.lecturas,
    productosDiversos: isla?.productos?.map((p) => ({ ...p, idProductoDiverso: p.id })),
    tiposPagos: isla?.pagos?.map((p) => ({ ...p, idTipoPago: p.id })),
    denominaciones: isla?.denominaciones?.map((p) => ({ ...p, idDenominacion: p.id })),
    lecturaModificada: lecturaProductoModificado,
    jarreoConsignacionModificado: jarreoConsignacionModificados,
    esGlobal: true,
  };

  const validaBotonSiguiente = () => {
    if (disableBtns) return true;
    if ((turno?.id ?? 0) === 0) return true;
    if (step > 3) return true;
    if (step === 3 && (Math.abs(diferencia) > 0 || Math.abs(diferenciaAceites) > 0)) return true;
    if (step === 1 && !Boolean(isla?.lecturas)) return true;
    return false;
  };

  const siguienteStep = () => {
    if (step === 0 && liquidada) {
      liquidadaMsg.open();
      return;
    }
    /*Validación de límite de venta permitido*/
    const acumDiff = isla?.lecturas?.filter(
      (item) => item.diferencia > LIMITEMAXPERMITIDO && item.lecturaInicial > item.lecturaFinal
    );
    if (step === 1 && acumDiff?.length > 0) {
      limiteVentasExcedidoMsg.open();
      return;
    }
    setStep((step) => step + 1);
  };

  const onSave = async () => {
    dispatch(insert(modeloLiquidacion))
      .then(hasDispatchError)
      .then(() => navigate(`/operaciones/liquidaciones/detalle/${btoa(JSON.stringify(turno))}`))
      .catch(() => {});
  };

  const onPreview = async () => {
    dispatch(preview(modeloLiquidacion))
      .then(hasDispatchError)
      .then(showInWindow)
      .catch(() => {});
  };

  useEffect(() => {
    loading.insert ? setSaving(true) : setSaving(false);
    loading.preview ? setProcessing(true) : setProcessing(false);
  }, [loading]);

  useEffect(initIsla, []);

  return {
    step,
    turno,
    turnoCargado: turno?.id > 0,
    isla,
    despachador,
    disableBtns,
    totales,
    saving,
    diferencia,
    diferenciaAceites,
    liquidadaMsg,
    processing,
    limiteVentasExcedidoMsg,
    LIQGLOBAL,
    esUltimoTurno: Object.keys(lastTurno).length !== 0 && lastTurno?.id !== turno?.id ? false : true,
    lecturaProductoModificado: lecturaProductoModificado,
    screen,
    asignaTurno: setTurno,
    asignaIsla: setIsla,
    asignaDespachador: setDespachador,
    pagosAceites: setPagosAceites,
    siguienteStep,
    anteriorStep: () => setStep((step) => step - 1),
    desactivaStepBtn: () => setDisableBtns(true),
    activaStepBtn: () => setDisableBtns(false),
    validaBotonSiguiente,
    onSave,
    onPreview,
  };
};

const round = (num, decimal) => {
  return +(Math.round(num + "e+" + decimal) + "e-" + decimal);
};
