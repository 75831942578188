import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUltimoAbierto } from "../../../store/operaciones/turnos";
import { hasDispatchError } from "../../../api/services/service";
import { DangerMessage, LoadingPanel, ToolBarIGas, WarningMessage } from "@paul-igas/igas-react-components";
import { clearErrors, selectAll, setTurnoActual } from "../../../store/operaciones/liquidaciones";
import { useModal } from "@paul-igas/igas-react-hooks";
import {
  ListadoLiqSection,
  NotTurnoSection,
  OtherLiqSection,
} from "../../../components/operaciones/liquidacion/sections";
import { LiquidacionContext } from "../../../contexts";

export function Liquidaciones() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { turnoActual, notTurno, turnoLoading } = UseTurnoLiquidacion();
  const { loading, liquidaciones, error } = useSelector((state) => state.liquidaciones);
  const [liqGlobal, setLiqGlobal] = useState(false);

  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);

  const handleinsert = () => {
    navigate("/operaciones/liquidaciones/agregar");
  };

  const handleRefresh = () => {
    if (!notTurno && !turnoLoading) {
      dispatch(selectAll({ idTurno: turnoActual?.id }))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const context = {
    turnoLoading,
    turnoActual,
    loading: loading.getAll,
    liquidaciones,
    liqGlobal,
    handleLiqGlobal: (v) => setLiqGlobal(v),
    handleRefresh,
  };

  useEffect(handleRefresh, [turnoActual]);
  useEffect(handleError, [error]);

  if (turnoLoading) return <LoadingPanel title='Cargando...' size={100} />;

  return (
    <LiquidacionContext.Provider value={context}>
      <ToolBarIGas
        title='Liquidaciones del turno'
        loading={false}
        onRefresh={!notTurno && !turnoLoading ? handleRefresh : () => {}}
        onInsert={!notTurno && !turnoLoading && !liqGlobal ? handleinsert : null}
      />

      {/* Sección de turno no existente */}
      {notTurno && !turnoLoading && <NotTurnoSection />}

      {/* Sección de opciones de liquidación */}
      {(notTurno && !turnoLoading) || <OtherLiqSection />}

      {/* Sección de listado de liquidaciones */}
      {(notTurno && !turnoLoading) || <ListadoLiqSection />}

      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </LiquidacionContext.Provider>
  );
}

export default Liquidaciones;

const UseTurnoLiquidacion = () => {
  const { turno } = useParams();
  const { loading } = useSelector((state) => state.turnos);
  const dispatch = useDispatch();
  const [turnoOperativo, setTurnoOperativo] = useState({});

  const asignaTurnoLiquidacion = () => {
    let turnoObj = JSON.parse(atob(turno));
    if ((turnoObj?.id ?? 0) === 0) {
      dispatch(getUltimoAbierto())
        .then(hasDispatchError)
        .then(({ payload }) => setTurnoOperativo(payload))
        .catch(() => {});
    } else setTurnoOperativo(turnoObj);
  };

  useEffect(asignaTurnoLiquidacion, [turno]);
  useEffect(() => {
    dispatch(setTurnoActual(turnoOperativo));
  }, [turnoOperativo]);

  return {
    notTurno: (turnoOperativo?.id ?? 0) === 0,
    turnoActual: turnoOperativo,
    turnoLoading: loading.getUltimoAbierto,
  };
};
