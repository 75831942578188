import { Alert, AlertTitle } from "@mui/material";

export const NotTurnoSection = () => {
  return (
    <Alert severity='info'>
      <AlertTitle>No se encontró turno abierto</AlertTitle>
      Actualmente no se encuentra un turno abierto en la estación de trabajo; es necesario contar con uno para operar en
      liquidaciones.
    </Alert>
  );
};

export default NotTurnoSection;
