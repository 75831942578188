import { Search } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ListaValesCredito from "./components/listaValesCredito";
import { LoadingPanel, SuccessMessage } from "@paul-igas/igas-react-components";
import ConsultarClientes from "./dialogs/consultarClientes";
import { formatDateTimeMX, getModelErrors, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";
import { getById, update } from "../../../store/operaciones/valescredito";
import { useDispatch, useSelector } from "react-redux";
import { wait } from "../../helpers/utils";
import TotalesDialog from "./components/totalesDialog";
import TurnoDialogInfo from "./components/turnoDialogInfo";

export function UpdateDialog({ open, onClose, postSubmit, turno }) {
  const dispatch = useDispatch();
  const { loading, valeCreditoIdSelected, valeCredito, error } = useSelector((state) => state.valesCredito);
  const data = useUpdateValesCredito(open);
  const [errors, setErrors] = useState({});
  const consultarClienteDlg = useModal(false);
  const successMsg = useModal(false);

  const asignarCliente = (payload) => {
    data.asignaCliente(payload);
  };

  const asignarDatos = ({ payload }) => {
    data.asignaCliente({ noClie: payload.noClie, nombre: payload.nombre });
    data.initialize(payload.valesCreditoDetalle);
  };

  const initDialog = () => {
    if (open) {
      dispatch(getById(valeCreditoIdSelected.id)).then(hasDispatchError).then(asignarDatos).catch(onClose);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setErrors({});
    onClose();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const modeloPaquete = {
      id: valeCredito.id,
      nombreCliente: data?.cliente?.nombre ?? "",
      claveCliente: data?.cliente?.noClie ?? 0,
      fechaTurno: turno.fecha,
      idTurno: turno.id,
      valeCreditoDetalle: [...(data?.listaVales ?? [])],
    };
    setErrors({});
    dispatch(update(modeloPaquete))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleError = () => {
    if (open) {
      if (error?.status === 400) setErrors(getModelErrors(error.message));
    }
  };
  useEffect(handleError, [error]);
  useEffect(initDialog, [open]);
  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='md' scroll='paper'>
        <DialogTitle>Actualizar paquete de vales de crédito</DialogTitle>
        {loading.getById && <LoadingPanel />}
        {!loading.getById && (
          <DialogContent dividers>
            <DialogContentText>
              Turno {zeroPad(turno.noTurno, 2)} del día {formatDateTimeMX(turno?.fecha ?? new Date())}
            </DialogContentText>
            <Grid container spacing={2} alignContent={"space-between"} sx={{ pt: 2 }}>
              <Grid item xs={12} sm={12} container>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    label='Cliente'
                    variant='standard'
                    value={data?.cliente?.nombre ?? ""}
                    helperText={errors?.nombreCliente}
                    FormHelperTextProps={{
                      style: { color: "red" },
                    }}
                    aria-readonly={true}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {!valeCredito?.valesCreditoDetalle?.some((vale) => vale.facturado) && (
                            <IconButton onClick={consultarClienteDlg.open} edge='end' disabled={loading.update}>
                              <Tooltip title='Seleccionar cliente'>
                                <Search />
                              </Tooltip>
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Card variant='outlined' style={{ border: "none" }}>
                  <CardHeader style={{ textAlign: "center" }} subheader='Listado de vales' />
                  <CardContent sx={{ mt: -3 }}>
                    <ListaValesCredito data={data} />
                    {Boolean(errors?.valeCreditoDetalle) && (
                      <Typography
                        variant='caption'
                        marginLeft={2}
                        style={{ color: "red" }}
                      >{`${errors?.valeCreditoDetalle}`}</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item xs={12} justifyContent='flex-end' spacing={2} sx={{ pr: 6 }}>
                <TotalesDialog totales={data.totales} />
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.update} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.update} type='button' onClick={onSubmit}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text='Paquete de vales se actualizó correctamente'
      />
      <ConsultarClientes
        open={consultarClienteDlg.isShowing}
        onClose={consultarClienteDlg.close}
        postSubmit={asignarCliente}
      />
    </>
  );
}

export default UpdateDialog;

const useUpdateValesCredito = (open) => {
  const [foliosVolumetricos, setFoliosVolumetricos] = useState([]);
  const [cliente, setCliente] = useState({ claveCliente: 0 });
  const [refresh, setRefresh] = useState(false);
  const [totales, setTotales] = useState({ importe: 0, volumen: 0, cantidadVales: 0 });
  const actualizaTotales = () => {
    setTotales({
      volumen: foliosVolumetricos?.reduce((a, b) => a + b.volumen, 0),
      importe: foliosVolumetricos?.reduce((a, b) => a + b.importe, 0),
      cantidadVales : foliosVolumetricos.length
    });
  };

  useEffect(actualizaTotales, [foliosVolumetricos]);
  const refreshValesDetalle = async () => {
    if (foliosVolumetricos.length > 0) {
      setRefresh(true);
      await wait(100);
    }
    setRefresh(false);
  };
  const initValesDetalle = () => {
    if (open === true) setFoliosVolumetricos([]);
  };

  useEffect(initValesDetalle, [open]);
  useEffect(() => {
    refreshValesDetalle();
  }, [foliosVolumetricos]);

  return {
    cliente,
    listaVales: foliosVolumetricos,
    open,
    totales,
    actualizaVales: setFoliosVolumetricos,
    asignaCliente: (c) => setCliente(c),
    initialize: (foliosVolumetricos) => setFoliosVolumetricos(foliosVolumetricos),
    refresh,
  };
};
