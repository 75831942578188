import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getValesSinLiquidarTurno } from "../../../../store/operaciones/valescredito";
import { hasDispatchError } from "../../../../api/services/service";
import { ColumnAlign, ColumnType, SimpleTable } from "@paul-igas/igas-react-components";

const TABLE_VALES = {
  columns: [
    { text: "Isla", name: "isla", colType: ColumnType.DataKey, digitos: 2 },
    { text: "Capturado", name: "capturado", colAlign: ColumnAlign.Right, colType: ColumnType.Money },
    { text: "Liquidado", name: "liquidado", colAlign: ColumnAlign.Right, colType: ColumnType.Money },
    { text: "Diferencia", name: "diferencia", colAlign: ColumnAlign.Right, colType: ColumnType.Money, digitos: 3 },
  ],
};

const TABLE_TOTALS = [
  {
    name: "action",
    colAlign: ColumnAlign.Left,
    isLabel: true,
    total: true,
  },
  { name: "capturado", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
  { name: "liquidado", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
  { name: "diferencia", colType: ColumnType.Money, colAlign: ColumnAlign.Right, total: true },
];

export const ValesSinLiquidar = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const turnos = useSelector((state) => state.turnos);
  const { loading, valesCreditoSinLiquidar } = useSelector((state) => state.valesCredito);
  const [totales, setTotales] = useState([]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const calculaTotales = () => {
    if ((valesCreditoSinLiquidar?.length ?? 0) > 0) {
      let cap = valesCreditoSinLiquidar.reduce((a, b) => a + b.capturado, 0);
      let liq = valesCreditoSinLiquidar.reduce((a, b) => a + b.liquidado, 0);
      let dif = valesCreditoSinLiquidar.reduce((a, b) => a + b.diferencia, 0);
      setTotales([{ capturado: cap, liquidado: liq, diferencia: dif }]);
    }
  };

  const initDlg = () => {
    if (open) {
      dispatch(getValesSinLiquidarTurno(turnos.turno.id))
        .then(hasDispatchError)
        .catch(() => {
          handleClose();
        });
    }
  };

  useEffect(initDlg, [open]);
  useEffect(calculaTotales, [valesCreditoSinLiquidar]);

  if (loading.getValesSinLiquidarTurno) return <></>;

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='sm' scroll='paper'>
      <DialogContent dividers>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Alert severity='warning'>
              No es posible cerrar el turno; se encontraron vales de crédito sin liquidar.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <SimpleTable
              table={TABLE_VALES}
              data={valesCreditoSinLiquidar}
              loading={loading.getValesSinLiquidarTurno}
              showPagination={false}
              rowTotals={{ columns: TABLE_TOTALS, data: totales }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' disableElevation onClick={handleClose}>
          Salir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ValesSinLiquidar;
