import { Alert, Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import { Catalogo } from "@paul-igas/igas-react-components";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ValesFacturadosRow from "../../valecredito/valesFacturadosRow";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Factura",
      name: "folioFactura",
      renderGroup: (value) => <ValesFacturadosRow data={value} />,
    },
  ],
};

export const ValesFacturados = ({ open, onClose, valesFacturados }) => {
  const dispatch = useDispatch();
  const turnos = useSelector((state) => state.turnos);

  const [totales, setTotales] = useState([]);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  // const calculaTotales = ({ payload }) => {
  //   let vol = payload.reduce((a, b) => a + b.volumen, 0);
  //   let imp = payload.reduce((a, b) => a + b.importe, 0);
  //   setTotales([{ volumen: vol, importe: imp }]);
  // };

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='sm' scroll='paper'>
        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Alert severity='warning'>
                No es posible reabrir el turno; se encontraron vales de crédito facturados.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Catalogo
                table={TABLE_DEFAULT}
                data={valesFacturados ?? []}
                keyColumn='folioFactura'
                disableHead
                showEmptyRows={false}
                showSearch={false}
                onRefresh={() => {}}
                showPagination={false}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation onClick={handleClose}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ValesFacturados;
