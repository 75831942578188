import { Button, Grid } from "@mui/material";
import { useModal } from "@paul-igas/igas-react-hooks";
import { ResumenValesDialog } from "../dialogs/resumenValesDialog";

export const ValesCreditoOptions = ( {turno} ) => {
  const modalResumenVales = useModal(false);

  return (
    <>
      <Grid container spacing={3} justifyContent='flex-end' sx={{ pb: 1 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Button fullWidth onClick={modalResumenVales.open} variant='outlined'>
            Resumen de vales de crédito
          </Button>
        </Grid>
      </Grid>

      <ResumenValesDialog
        open={modalResumenVales.isShowing}
        onClose={modalResumenVales.close}
        turno={turno}
      />
    </>
  );
};

export default ValesCreditoOptions;