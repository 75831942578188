import api from "../service";

const _controller = "valeCredito/";

export async function GetByIdTurno(params) {
  const res = await api.get(`${_controller}byTurno/${params}`);
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export async function GetById(params) {
  const res = await api.get(`${_controller}${params}`);
  return res.data;
}

export async function Update(params) {
  const res = await api.put(`${_controller}`, params);
  return res.data;
}

export async function Delete(params) {
  const res = await api.delete(`${_controller}${params}`);
  return res.data;
}

export async function GetImporteValesIslaTurno(params) {
  const res = await api.get(`${_controller}liquidacion/${params.idIsla}&${params.idTurno}`);
  return res.data;
}

export async function GetNoExportadosByIdTurno(params) {
  const res = await api.get(`${_controller}TurnoLiquidacion/byTurno/${params}`);
  return res.data;
}

export async function ExportarValesPendientes(params) {
  const res = await api.post(`${_controller}TurnoLiquidacion`, params);
  return res.data;
}

export async function GetDiferenciaValesTurno(params) {
  const res = await api.get(`${_controller}TurnoLiquidacion/diferenciaVales/${params}`);
  return res.data;
}

export async function GetValesSinLiquidarTurno(params) {
  const res = await api.get(`${_controller}TurnoLiquidacion/sinLiquidar/${params}`);
  return res.data;
}
export async function GetResumenValesIsla(params) {
  const res = await api.get(`${_controller}getResumenValesIsla/${params}`);
  return res.data;
}

export async function GetResumenValesCombustible(params) {
  const res = await api.get(`${_controller}getResumenValesCombustible/${params}`);
  return res.data;
}

export async function GetValesFacturados(params) {
  const res = await api.post(`${_controller}TurnoLiquidacion/ValesFacturados`, params);
  return res.data;
}

export default {
  GetByIdTurno,
  GetById,
  Insert,
  Delete,
  Update,
  GetImporteValesIslaTurno,
  GetNoExportadosByIdTurno,
  ExportarValesPendientes,
  GetDiferenciaValesTurno,
  GetValesSinLiquidarTurno,
  GetResumenValesIsla,
  GetResumenValesCombustible,
  GetValesFacturados,
};
