import { Button, Tooltip } from "@mui/material";
import { GetConfiguration } from "../../../../helpers/variablesEstacion";
import { Edit } from "@mui/icons-material";

export const OptionTableRow = (props) => {
  const { data = {}, onEdit = () => {}, esDenominacion = false, validaEfectivo = true } = props;

  const TipoPagoEfectivo = Number(GetConfiguration(5)?.valor);
  const handleEdit = () => {
    onEdit(data);
  };

  if (esDenominacion && TipoPagoEfectivo === 0) return null;

  const esEfectivo = () => {
    return (data?.idTipoPago ?? 0) === TipoPagoEfectivo;
  };

  if (validaEfectivo && esEfectivo()) return null;

  if((data?.clase ?? 0) === 6) return null;

  return (
    <Tooltip title='Editar'>
      <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleEdit}>
        <Edit fontSize='small' color='action' />
      </Button>
    </Tooltip>
  );
};

export default OptionTableRow;
