import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { UseLiquidacionContext } from "../../../../contexts";
import { IslasEnCerosDialog } from "../dialogs/islasEnCerosDialog";
import { useModal } from "@paul-igas/igas-react-hooks";

export const OtherLiqSection = () => {
  const navigate = useNavigate();
  const { turnoActual, loading, liquidaciones, handleRefresh, liqGlobal, handleLiqGlobal } = UseLiquidacionContext();

  const modalIslas = useModal(false);

  const handleGlobal = () => {
    navigate("/operaciones/liquidaciones/global");
  };

  const verificaGlobal = () => {
    let esGlobal = liquidaciones?.some((x) => x.esGlobal === true);
    handleLiqGlobal(esGlobal);
  };

  useEffect(verificaGlobal, [liquidaciones]);

  return (
    <>
      <Grid container spacing={1} justifyContent='flex-end' sx={{ pb: 1 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Button fullWidth onClick={handleGlobal} variant='outlined' disabled={loading || liquidaciones.length > 0}>
            Liquidación global del turno
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Button fullWidth onClick={modalIslas.open} variant='outlined' disabled={loading || liqGlobal}>
            Liquidar islas sin movimientos
          </Button>
        </Grid>
      </Grid>

      <IslasEnCerosDialog
        open={modalIslas.isShowing}
        onClose={modalIslas.close}
        postSubmit={handleRefresh}
        turno={turnoActual}
      />
    </>
  );
};

export default OtherLiqSection;
