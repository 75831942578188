import { Alert, AlertTitle, Grid, Paper, Box, Typography } from "@mui/material";
import { Info, MonetizationOn } from "@mui/icons-material";
import { formatDateTimeMX, monedaMX, zeroPad } from "@paul-igas/igas-react-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUltimoAbierto, getUltimo } from "../../../store/operaciones/turnos";
import { hasDispatchError } from "../../../api/services/service";
import { boldText } from "../../helpers/utils";

export function InfoPanel({ data }) {
  const dispatch = useDispatch();
  const { step, turno, isla, despachador, asignaTurno, totales, lastTurno } = data;
  const { loading } = useSelector((state) => state.turnos);
  const { turnoActual } = useSelector((state) => state.liquidaciones);

  const actualizaTurno = ({ payload }) => {
    if (Object.keys(turnoActual).length !== 0) {
      asignaTurno(turnoActual);
    } else {
      asignaTurno(payload);
    }
  };

  const actualizaLastTurno = ({ payload }) => {
    lastTurno(payload);
  };
  const initPanel = () => {
    if (!turno) {
      dispatch(getUltimoAbierto())
        .then(actualizaTurno)
        .then(hasDispatchError)
        .catch(() => {});
      dispatch(getUltimo())
        .then(actualizaLastTurno)
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  useEffect(initPanel, [step]);

  if (!loading.getUltimoAbierto && (turno?.id ?? 0) === 0)
    return (
      <Alert severity='info' style={{ marginTop: 8 }}>
        <AlertTitle>Información</AlertTitle>
        No se encontró un turno abierto en la estación de trabajo actual.
      </Alert>
    );

  return (
    <Grid container justifyContent='space-between' spacing={1}>
      <Grid item xs={12} md={6}>
        <Paper style={{ backgroundColor: "#e8f4fd", color: "#0d3c61" }} elevation={0}>
          <Box p={2}>
            {loading.getUltimoAbierto && (
              <Typography variant='body2' display='block'>
                Buscando turno actual...
              </Typography>
            )}
            {!loading.getUltimoAbierto && (turno?.id ?? 0) > 0 && (
              <Box display='flex'>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={1} textAlign='center'>
                        <Info />
                      </Grid>
                      <Grid item xs={4}>
                        <Typography variant='subtitle1' display='block'>
                          {boldText("Turno: ")}
                          {zeroPad(turno?.noTurno, 2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant='subtitle1' display='block'>
                          {boldText("Fecha: ")}
                          {formatDateTimeMX(turno?.fecha)}
                        </Typography>
                      </Grid>
                    </Grid>

                    {step > 0 && (
                      <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle1' display='block'>
                            {boldText("Isla: ")}
                            {data.LIQGLOBAL || data.esGlobal ? "TODAS" : zeroPad(isla?.isla, 2)}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography noWrap variant='subtitle1' display='block'>
                            {boldText("Desp: ")}
                            <span>{despachador?.nombreCompleto}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>

      {!loading.getUltimoAbierto && step > 0 && (
        <Grid item xs={12} md={6}>
          <Paper style={{ backgroundColor: "#e8f4fd", color: "#0d3c61" }} elevation={0}>
            <Box p={2}>
              <Box display='flex'>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={1} textAlign='center'>
                        <MonetizationOn />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle1' display={"block"}>
                          {boldText("Combustible: ")}
                          <span style={{ textAlign: "left" }}>{monedaMX.format(totales?.combustibles ?? 0)}</span>
                        </Typography>
                      </Grid>

                      <Grid item xs={5}>
                        {step > 1 && (
                          <Typography variant='subtitle1' display='block'>
                            {boldText("Productos div: ")}
                            <span style={{ textAlign: "left" }}>{monedaMX.format(totales?.productos ?? 0)}</span>
                          </Typography>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6}>
                        {step > 2 && (
                          <Typography variant='subtitle1' display='block'>
                            {boldText("Total: ")}
                            {monedaMX.format((totales?.combustibles ?? 0) + (totales.productos ?? 0))}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        {step > 2 && (
                          <Typography variant='subtitle1' display='block'>
                            {boldText("Pagos: ")}
                            {monedaMX.format(totales?.pagos ?? 0)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
}

export default InfoPanel;
