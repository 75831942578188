import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import { SuccessMessage, IGasForm, IGasDecimalField } from "@paul-igas/igas-react-components";
import { decimalVal, useModal } from "@paul-igas/igas-react-hooks";

export function PagoEditDialog({ open, onClose, postSubmit, data }) {
  const [errors, setErrors] = useState();

  const successMsg = useModal(false);

  const validaForm = (form) => {
    const newErrors = {};

    newErrors.importe = form.importe.toString() === "" ? "'Importe' es requerido." : "";

    setErrors(newErrors);

    return Object.values(newErrors).every((x) => x === "");
  };

  const handleSubmit = (pago) => {
    if (validaForm(pago)) {
      let importe = parseFloat(pago.importe);
      let newData = { ...data, importe };
      onClose();
      successMsg.open();
      postSubmit(newData);
    }
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>Editar importe</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{data?.descripcion}</DialogContentText>
          <br />
          <IGasForm id='pago-edit-dlg' onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasDecimalField
                  autoFocus
                  label='Importe'
                  name='importe'
                  defaultValue={decimalVal(2).format(data?.importe ?? 0)}
                  error={errors?.importe}
                  maxLength={10}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='pago-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
    </>
  );
}

export default PagoEditDialog;
