import React from "react";
import {
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  DataTable,
  LoadingPanel,
  ToolBarIGas,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { searchComparator, useModal } from "@paul-igas/igas-react-hooks";

import { Alert, AlertTitle, Grid } from "@mui/material";
import { getUltimoAbierto } from "../../store/operaciones/turnos";
import { clearErrors, selectByIdTurno, setTurno, setValeCreditoIdSelected } from "../../store/operaciones/valescredito";
import { hasDispatchError } from "../../api/services/service";
import {
  DeleteDialog,
  DetalleDialog,
  InsertDialog,
  OptionTableRow,
  TurnoInfo,
  UpdateDialog,
} from "../../components/operaciones/valecredito";
import { useParams } from "react-router-dom";
import { ValesCreditoOptions } from "../../components/operaciones/valecredito/sections/valeCreditoOptions";

const TABLE_DEFAULT = {
  columns: [
    {
      text: "Cliente",
      textAlt: "Cliente",
      name: "nombre",
      colAlign: ColumnAlign.Left,
      ColumnPriority: ColumnPriority.High,
    },
    {
      text: "Cantidad de vales",
      textAlt: "Cantidad Vales",
      name: "cantidadVales",
      colAlign: ColumnAlign.Right,
      ColumnPriority: ColumnPriority.High,
    },
    {
      text: "Volumen",
      textAlt: "Vol.",
      name: "volumen",
      colAlign: ColumnAlign.Right,
      ColumnPriority: ColumnPriority.High,
      colType: ColumnType.Decimal,
      digitos: 3,
    },
    {
      text: "Importe",
      textAlt: "Imp.",
      name: "importe",
      colAlign: ColumnAlign.Right,
      ColumnPriority: ColumnPriority.High,
      colType: ColumnType.Money,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function ValesCredito() {
  const dispatch = useDispatch();
  const { loading, valesCredito, error } = useSelector((state) => state.valesCredito);
  const { notTurno, turnoActual, turnoLoading } = GetTurno();
  const [search, setSearch] = useState("");
  const [data, setData] = useState(valesCredito);
  const modalDetalle = useModal(false);
  const modalInsert = useModal(false);
  const modalDelete = useModal(false);
  const modalUpdate = useModal(false);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleinsert = () => {
    modalInsert.open();
  };

  const handleSelectValeCredito = (id) => {
    dispatch(setValeCreditoIdSelected(id));
  };

  const handleRefresh = () => {
    if (!notTurno && !turnoLoading) {
      dispatch(selectByIdTurno(turnoActual?.id))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  const handleSearch = () => {
    if (search === "") {
      setData(valesCredito);
    } else {
      let _data = searchComparator(valesCredito, search);
      setData(_data);
    }
  };

  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };
  useEffect(handleRefresh, [notTurno, turnoLoading]);
  useEffect(handleError, [error]);

  useEffect(handleSearch, [valesCredito, search]);

  if (turnoLoading) return <LoadingPanel title='Cargando...' size={100} />;
  return (
    <>
      <ToolBarIGas
        title='Paquetes de vales de crédito'
        loading={false}
        onInsert={!notTurno && !turnoLoading ? handleinsert : null}
        search={search}
        onSearch={!notTurno && !turnoLoading ? (val) => setSearch(val) : false}
        onRefresh={!notTurno && !turnoLoading ? handleRefresh : () => {}}
      />
      {(notTurno && !turnoLoading) || <ValesCreditoOptions turno={turnoActual} />}
      {notTurno && !turnoLoading && (
        <Alert severity='info'>
          <AlertTitle>No se encontró turno abierto</AlertTitle>
          Actualmente no se encuentra un turno abierto en la estación de trabajo; es necesario contar con uno para
          operar en paquetes de vales de crédito.
        </Alert>
      )}
      {(notTurno && !turnoLoading) || (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TurnoInfo loading={turnoLoading} turno={turnoActual} valesCredito={valesCredito} />
          </Grid>
          <Grid item xs={12}>
            <DataTable
              table={TABLE_DEFAULT}
              data={data}
              loading={loading.getByIdTurno}
              rowOptions={
                <OptionTableRow
                  onEdit={modalUpdate.open}
                  onSelect={handleSelectValeCredito}
                  onDelete={modalDelete.open}
                  onDetalle={modalDetalle.open}
                />
              }
            />
          </Grid>
        </Grid>
      )}
      <InsertDialog
        open={modalInsert.isShowing}
        onClose={modalInsert.close}
        postSubmit={handleRefresh}
        turno={turnoActual}
      />
      <UpdateDialog
        open={modalUpdate.isShowing}
        onClose={modalUpdate.close}
        postSubmit={handleRefresh}
        turno={turnoActual}       
      />
      <DetalleDialog
        open={modalDetalle.isShowing}
        onClose={modalDetalle.close}
        postSubmit={handleRefresh}
        turno={turnoActual}
      />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export default ValesCredito;

const GetTurno = () => {
  const { turno } = useParams();
  const { loading } = useSelector((state) => state.turnos);
  const dispatch = useDispatch();
  const [turnoOperativo, setTurnoOperativo] = useState({});

  const asignaTurnoLiquidacion = () => {
    let turnoObj = JSON.parse(atob(turno));
    if ((turnoObj?.id ?? 0) === 0) {
      dispatch(getUltimoAbierto())
        .then(hasDispatchError)
        .then(({ payload }) => setTurnoOperativo(payload))
        .catch(() => {});
    } else setTurnoOperativo(turnoObj);
  };

  useEffect(asignaTurnoLiquidacion, [turno]);

  return {
    notTurno: (turnoOperativo?.id ?? 0) === 0,
    turnoActual: turnoOperativo,
    turnoLoading: loading.getUltimoAbierto,
  };
};
